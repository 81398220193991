.header {
  @apply top-0 w-full mt-0 shadow-sm h-[72px] laptop:h-[88px] flex sticky tablet:relative z-[150] bg-black;

  .header__link {
    @apply ui-text border-b-2 border-transparent whitespace-nowrap text-white relative flex items-center justify-center flex-1 px-3;

    span {
      @apply border-b-2 border-transparent;
    }

    &:hover,
    &:focus,
    &:active {
      @apply no-underline;
      span {
        @apply border-primary-hrg-red;
      }
    }

    &.active {
      @apply text-penguin-yellow;

      &:hover,
      &:focus,
      &:active {
        @apply no-underline;
        span {
          @apply border-transparent;
        }
      }
    }
  }

  .header__main-menu {
    @apply flex gap-4 justify-around flex-1 items-center;
  }
}
