.menu-toggle {
  @apply block mr-1 laptop:mr-6 z-10 select-none cursor-pointer;

  .menu-toggle__line {
    @apply block relative z-90 bg-white mb-1 h-[2px];
    transform-origin: 4px 0px;

    &--top {
      @apply w-[18px];
    }

    &--middle {
      @apply w-[12px];
    }

    &--bottom {
      @apply mb-0 w-[18px];
      transform-origin: 0% 100%;
    }
  }
}

.side-menu {
  @apply flex flex-col w-full left-0 antialiased z-[150] fixed top-0 bottom-0 bg-off-black;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  &.isMenuOpen {
    @apply transform-none;
  }
}

.isMenuOpen {
  .menu-toggle__line--middle {
    @apply hidden;
  }

  .menu-toggle__line {
    transform: rotate(45deg) translate(2px, 0);
  }

  .menu-toggle__line--bottom {
    @apply w-[18px];
    transform: rotate(-45deg) translate(0, 4px);
  }
}
