.triangle {
  display: none;
}

@supports (clip-path: inset(50%)) {
  .triangle {
    display: block;
    height: 20px;
    width: 20px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    border-radius: 0 0 0 0.25em;
  }
}
