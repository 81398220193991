.truncate-article-text {
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply hidden;
  }

  p,
  h1,
  h2,
  h3:nth-child(1) {
    @apply block;
  }
  p,
  h1,
  h2,
  h3:nth-child(2) {
    @apply block;
  }

  p,
  h1,
  h2,
  h3:nth-child(3) {
    @apply block;
  }
}
