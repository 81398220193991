.map-marker-ship {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 71px;
  height: 23px;
  cursor: pointer;
}

.map-marker-inner {
  width: 100%;
  height: 100%;
}
