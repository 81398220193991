.smt-covwidget {
  @apply font-jost font-normal !important;
}

.smt-covwidget-navtab-switcher {
  @apply rounded-none !important;
}

.smt-covwidget-navtab-switcher-tab {
  @apply rounded-none !important;
}

.smt-covwidget-navtab-switcher-active {
  @apply bg-hx-caviar hover:text-white font-normal !important;
}

.smt-combobox-input {
  @apply font-normal !important;
}

.smt-combobox {
  @apply border-hx-mistyindigo !important;
}

.smt-combobox-container {
  @apply border-none !important;
}

.smt-combobox-results {
  @apply border-hx-mistyindigo !important;
}

.smt-primary {
  @apply bg-hx-caviar !important;
}

.smt-primary:disabled {
  @apply bg-hx-mistyindigo !important;
}

.smt-covwidget-triptype-radiogroup-label:before {
  @apply border-hx-caviar !important;
}

.smt-covwidget-triptype-radiogroup-label:after {
  @apply border-hx-caviar !important;
  @apply bg-hx-caviar !important;
}

.smt-covwidget-navtab span,
.smt-covwidget-navtab li {
  @apply hover:text-hx-caviar !important;
}

.smt-covwidget-navtab-active {
  @apply text-hx-caviar  !important;
  @apply border-b-4 border-hx-caviar !important;
}

.smt-covwidget-share-link-btn {
  @apply hover:text-hx-caviar !important;
}

.smt-font-headings {
  @apply font-jost font-normal !important;
}

.smt-expandible-options {
  @apply rounded-none !important;
}

.smt-expandible-option-content {
  @apply border-hx-sand !important;
}

.smt-covwidget-transit-container {
  @apply rounded-none bg-hx-caviar   !important;
}

.smt-covwidget-entrydocs-document_number {
  @apply bg-hx-sand !important;
}

.smt-covwidget-avatar {
  @apply bg-hx-sand !important;
}

.smt-covwidget-entrydocs-document_content a {
  @apply text-black hover:text-hx-caviar !important;
}

.smt-covwidget-section-allowance {
  @apply bg-hx-sand border-hx-sand !important;
}

.smt-covwidget-entrydocs-list li {
  @apply hover:border-none hover:bg-indigo-light-100  !important;
}

.smt-covwidget-entrydocs-option-toggle {
  @apply text-hx-caviar !important;
}
