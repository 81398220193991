.contentfulTextBlock {
  @apply max-w-3xl;

  p {
    @apply mb-3 body-text-1;
  }

  p:last-of-type {
    @apply mb-0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-5 mt-8;
  }

  a {
    @apply underline;
  }
}

.contentfulTextBlockOnDark {
  p {
    @apply mb-3;
    @apply text-white;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-5 mt-8;
    @apply text-white;
  }
  h2:first-of-type {
    @apply mt-0;
  }
}

.contentfulTextBlockRouteOverview {
  p {
    @apply mb-3;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-6;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-xl font-jost;
  }

  a {
    @apply underline;
  }
}

.contentfulTextBlockHelpCenter {
  p {
    @apply mb-3;
  }

  button p {
    @apply mb-0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-5 mt-8;
  }

  h3,
  h4,
  h5,
  h6 {
    @apply text-xl font-jost;
  }

  a {
    @apply underline;
  }
}

.contentfulTextBlockPageIntroduction {
  p {
    @apply mb-3;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-5 mt-0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-xl font-jost;
  }

  a {
    @apply underline;
  }
}
