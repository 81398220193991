.carousel-slider {
  @apply overflow-hidden bg-warm-gray-2;
  border-radius: 100px;
  button {
    touch-action: none;
  }
}

.carousel-slider-transitionable {
  background: linear-gradient(to right, #d40000 50%, #e5e3e3 50%) right;
  background-size: 200%;
}

.carousel-slider-transitioned {
  background: linear-gradient(to right, #e5e3e3 50%, #ffffff 50%) right;
  background-size: 200%;
}

.carousel-slider-transitioning {
  background-position: left;
  transition: background-position;
}

.carousel-nav-arrow {
  top: calc(50% - 30px);
}

.carousel-hide-unless-hovering {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s 0.3s;
}
.carousel-wrapper:hover .carousel-hide-unless-hovering {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s, visibility 0s;
}
