.sharedSlider {
  width: 100%;
  max-width: 500px;
  height: 26px;
  margin: auto;

  .shared-slider-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 1px solid #cbcccb;
    border-radius: 100%;
    display: block;
    width: 24px;
    height: 24px;
  }

  .shared-slider-track {
    position: relative;
    background: black;
    height: 1px;
    top: 11px;
    &.shared-slider-track-0,
    &.shared-slider-track-2 {
      background: #999;
    }
  }
}
