.radio + span::after {
  content: '';
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3c4c65;
}

.radio:checked + span::after {
  @apply block;
}

.radioTag:checked + span {
  @apply bg-black text-white;
}
