.animate-shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(
    to right,
    rgb(229, 227, 227) 4%,
    #f1f1f1 25%,
    rgb(229, 227, 227) 36%
  );
  background-size: 1000px 100%;
}

.animate-shimmer-dark {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(
    to right,
    rgb(59, 59, 59) 4%,
    #5a5a5a 25%,
    rgb(59, 59, 59) 36%
  );
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
