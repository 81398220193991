@keyframes slide-in-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out-down {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.sharedModal {
  --animation-slide-in-up: slide-in-up 0.5s cubic-bezier(0.25, 0, 0.3, 1);
  --animation-slide-out-down: slide-out-down 0.5s cubic-bezier(0.25, 0, 0.3, 1);
  --animation-backdrop-fade-in: fade-in 0.5s cubic-bezier(0.25, 0, 0.3, 1);
  --animation-backdrop-fade-out: fade-out 0.5s cubic-bezier(0.25, 0, 0.3, 1);

  & > dialog {
    &:not([open]) {
      opacity: 0;
      pointer-events: none;
    }

    &[open] {
      opacity: 1;
    }

    &::backdrop {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .sharedModal {
    & > dialog.opening[open] {
      animation: var(--animation-slide-in-up) forwards;
    }
    & > dialog.opening[open]::backdrop {
      animation: var(--animation-backdrop-fade-in) forwards;
    }
    & > dialog.closing {
      animation: var(--animation-slide-out-down) forwards;
    }
    & > dialog.closing::backdrop {
      animation: var(--animation-backdrop-fade-out) forwards;
    }

    .sharedModalOverlay {
      &.open {
        transform: translateY(0);
        display: block;
      }
      &.closed {
        transform: translateY(100%);
        display: none;
      }
      &.opening {
        animation: var(--animation-slide-in-up) forwards;
        display: block;
      }
      &.closing {
        animation: var(--animation-slide-out-down) forwards;
      }
    }
  }
}
