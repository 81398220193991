.switch-slider:before {
  @apply absolute bg-white h-6 w-6 left-1 bottom-1 transition-all duration-300 rounded-full;
  content: '';
}

.switch-slider:not([disabled]):active:before {
  @apply bg-light-hrg-red;
}

.switch input:checked + .switch-slider {
  @apply bg-primary-hrg-red;
}

.switch input:not([disabled]):focus + .switch-slider,
.switch input:not([disabled]) + .switch-slider:hover {
  @apply ring-2;
}

.switch input:not(([disabled])) ~ * {
  cursor: pointer;
}

.switch input:checked + .switch-slider:before {
  transform: translateX(28px);
}
