.seasonalityActivityBest {
  @apply bg-iceberg-blue;

  &:hover {
    @apply bg-opacity-80;
  }
}
.seasonalityActivityGood {
  @apply bg-frozen-blue;
  &:hover {
    @apply bg-opacity-80;
  }
}
