.expedition-planner-container {
  @apply bg-black w-full left-0 antialiased z-80 h-full fixed top-0 pb-20;
  transform-origin: 0% 0%;
  transform: translate(0, -150%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  &.isOpen {
    @apply transform-none;
  }
}
